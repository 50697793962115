<script lang="ts">
  /**
  * The ApplyOrientationForm component is used to display the apply orientation form.
  */
  import { createEventDispatcher } from "svelte";
  import { field, form } from "svelte-forms";
  import { get } from "svelte/store";
  import { useOktaStore } from "$lib/utils/auth-store";
  import { useCampaignStore } from "$lib/utils/campaign-store";
  import { useSessionStore } from "$lib/utils/session-store";
  import { useCookiesStore } from "$lib/utils/cookie-store";
  import { useOrientationStore } from "$lib/utils/orientation-store";
  import { PopupUtils } from "$lib/utils/popup-utils";
  import {
    required as requiredValidation,
    email as emailValidation,
    pattern as patternValidation,
  } from 'svelte-forms/validators';
  import { arrayRequired } from "$lib/utils/forms";
	import CtaServicesApiClientSingleton from "$lib/api/CtaServicesApiClientSingleton";
  import type { SubmitOrientationRequestRequest } from '$lib/api/generated/cta-services';
  import { TextField, CustomSelect, SwitchToggle } from "$lib/components/atoms/Field";
	import { createCtaEvent } from "$lib/utils/cta-flow/helpers";

  export let loading: boolean;
  $: loading = false;

  const authStore = useOktaStore();
  const campaignStore = useCampaignStore();
  const sessionStore = useSessionStore();
  const cookiesStore = useCookiesStore();
  const orientationStore = useOrientationStore();
  const campaignData = get(campaignStore.campaignData);
  const firstClickCampaign = get(campaignStore.firstClickCampaignData);
  const webid = get(campaignStore.webid);

  const ctaService = CtaServicesApiClientSingleton.getInstance();

  const dispatch = createEventDispatcher();

  // form variables
  const userInfo = authStore.userInfo;
  const isLoggedIn = authStore.isLoggedIn;
  const pendingUserInfo = sessionStore.pendingUserInfo;
  const companyStatus = sessionStore.companyStatus;

  const name = field(
    'name',
    $userInfo?.name || $pendingUserInfo?.name || '',
    [requiredValidation()],
    { valid: false }
  );
  const lastname = field(
    'lastname',
    $userInfo?.surname || $pendingUserInfo?.lastname || '',
    [requiredValidation()],
    { valid: false }
  );
  const email = field(
    'email',
    $userInfo?.email || $pendingUserInfo?.email || '',
    [emailValidation()],
    { valid: false }
  );
  const phone = field(
    'phone',
    $userInfo?.phone || $pendingUserInfo?.phone || '',
    [requiredValidation(), patternValidation(/^[0-9]+$/)],
    { valid: false }
  );
  const occupation = field(
    'occupation',
    null,
    [requiredValidation()],
    { valid: false }
  );
  const topics = field(
    'topics',
    null,
    [arrayRequired()],
    { valid: false }
  );
  const frequency = field(
    'frequency',
    null,
    [requiredValidation()],
    { valid: false }
  );
  const scholarship = field(
    'scholarship',
    false,
    [],
    { valid: false }
  );
  const financing = field(
    'financing',
    false,
    [],
    { valid: false }
  );
  const subscribe = field(
    'subscribe',
    $userInfo?.subscribe || $pendingUserInfo?.subscribe || false,
    [],
    { valid: false }
  );

  const orientation = form(
    name,
    lastname,
    email,
    phone,
    occupation,
    topics,
    frequency,
    scholarship,
    financing,
    subscribe,
  );

  const buildRequest = () : SubmitOrientationRequestRequest => {
    return {
      bsid: $isLoggedIn && $userInfo?.bsid ? $userInfo.bsid : $pendingUserInfo ? $pendingUserInfo.bsid : '',
      id: $isLoggedIn && $userInfo?.id ? $userInfo.id : $pendingUserInfo ? $pendingUserInfo.id : '',
      name: $name.value,
      surname: $lastname.value,
      email: $email.value,
      phone: $phone.value,
      consent1: $subscribe.value,
      consent2: false,
      consent3: false,
      referrer: campaignData?.referrer || '',
      utmCampaignSource: campaignData?.utm_source || '',
      utmCampaignMedium: campaignData?.utm_medium || '',
      utmCampaignName: campaignData?.utm_campaign || '',
      utmCampaignTerm: '',
      utmCampaignContent: '',
      fbclid: campaignData?.fbclid || '',
      gclid: campaignData?.gclid || '',
      gclsrc: campaignData?.gclsrc || '',
      websiteSessionData: {
        referrer: firstClickCampaign?.referrer || '',
        utmCampaignSource: firstClickCampaign?.utm_source || '',
        utmCampaignMedium: firstClickCampaign?.utm_medium || '',
        utmCampaignName: firstClickCampaign?.utm_campaign || '',
        utmCampaignTerm: '',
        utmCampaignContent: '',
        fbclid: firstClickCampaign?.fbclid || '',
        gclid: firstClickCampaign?.gclid || '',
        gclsrc: firstClickCampaign?.gclsrc || '',
        type: firstClickCampaign?.type || null,
        webid: webid,
      },
      device: firstClickCampaign?.device,
      b2b: $companyStatus,
      // orientation data
      orientation: {
          thematicAreas: $topics.value
            ? $topics.value.join(";")
            : "",
          currentOccupation: $occupation.value,
          yearsOfEmployment: "",
          preferredFrequency: $frequency.value,
          financing: $financing.value,
          scholarship: $scholarship.value,
      },
      message: "",
    }
  }

  export const handleSubmit = () => {
    loading = true;

    console.log('orientation', $orientation);
    orientation.validate();

    if ($orientation.valid) {
      loading = true;

      const orientationReq: SubmitOrientationRequestRequest = buildRequest();

      ctaService.then(async (client) => {
        try { 
          const orientationRes = await client.submitOrientationRequest(orientationReq, createCtaEvent('orientation_request'));

          if (orientationRes.uid) {
            PopupUtils.setPopupExpiration();
            
            if (typeof window != "undefined") {
              // @ts-ignore
              window.dataLayer = window.dataLayer || [];
              // @ts-ignore
              window.dataLayer.push({
                event: "BBevent",
                category: "Orientamento",
                action: "Orientamento_Request",
                label: "NOT SET",
                event_id: "1",
                page_path: window.location.pathname,
                UserStatus: $isLoggedIn ? "true" : "false",
                CP: cookiesStore.getCookie("cookie_notice_accepted"),
                B2B: $companyStatus ? "true" : "false",
              });
            } 

            orientationStore.setOrientationRequest({
              ...orientationReq,
            });
            
            dispatch("success");  
          } 
        } catch (e) {
          console.error("error submitOrientationRequest", e);
        } finally {
          loading = false;
        }
      });
    } else {
      loading = false;
    }
  }

  function handleTopicsChange(event) {
    topics.set(event.detail.value);
    
    const lastSelectedValue = $topics.value[$topics.value.length - 1];
    
    if (lastSelectedValue === "ND" && $topics.value.length > 1) {
      topics.set(["ND"])
    } else if (lastSelectedValue !== "ND" && $topics.value.includes("ND")) {
      let choosenTopics = $topics.value.filter((topic) => topic !== "ND");
      topics.set(choosenTopics);
    }
  }
</script>

<!-- svelte-ignore component-name-lowercase -->
<form class="col-span-full" on:submit|preventDefault={handleSubmit}>
  <TextField
    class="col-span-full md:col-span-3"
    name="name"
    id="name"
    type="text"
    label="Nome *"
    disabled={loading}
    autocomplete="given-name"
    bind:value={$name.value}
    required
    error={
      $orientation.hasError('name.required')
        ? 'Inserisci il tuo nome'
        : null
    }
  />
  <TextField
    class="col-span-full md:col-span-3"
    name="last-name"
    id="last-name"
    type="text"
    label="Cognome *"
    disabled={loading}
    autocomplete="family-name"
    bind:value={$lastname.value}
    required
    error={
      $orientation.hasError('lastname.required')
        ? 'Inserisci il tuo cognome'
        : null
    }
  />
  <TextField
    class="col-span-full md:col-span-3"
    name="email"
    id="email"
    type="email"
    label="Email *"
    disabled={loading}
    bind:value={$email.value}
    autocomplete="email"
    required
    error={
      $orientation.hasError('email.not_an_email')
        ? 'L’indirizzo e-mail non risulta valido, controlla l’indirizzo inserito'
        : null
    }
  />
  <TextField
    class="col-span-full md:col-span-3"
    name="phone"
    id="phone"
    type="number"
    label="Telefono *"
    disabled={loading}
    bind:value={$phone.value}
    autocomplete="tel"
    required
    error={
      $orientation.hasError('phone.required')
        ? 'Inserisci il tuo numero di telefono'
        : null
    }
  />
  <CustomSelect
    class="col-span-full"
    name="occupation"
    id="occupation"
    label="Attualmente sei? *"
    bind:value={$occupation.value}
    options={[
      { label: "Laureato/Laureando", value: "neolaureato" },
      { label: "Lavoratore", value: "lavoratore" },
      { label: "Neo diplomato", value: "neodiplomato" },
      { label: "Altro", value: "altro" },
    ]}
    error={
      $orientation.hasError('occupation.required')
        ? 'Seleziona la tua situazione attuale'
        : null
    }
  />
  {#if $occupation.value === "neodiplomato" || $occupation.value === "neolaureato"}
    <div class="flex items-start py-3 col-span-full">
      <SwitchToggle 
        class="col-span-full md:col-span-2"
        bind:checked={$scholarship.value}
        disabled={loading} 
      />
      <span class="ml-2">
        Sei interessato ad un master o corso che prevede uno stage al termine?
      </span>
    </div>
  {/if}
  <CustomSelect
    class="col-span-full"
    name="topics"
    id="topics"
    label="Quale tematica vorresti approfondire? *"
    bind:value={$topics.value}
    on:change={handleTopicsChange}
    multiple
    minLength={0}
    nullOptionValue="ND"
    options={[
      { label: "Amministrazione, Finanza e Controllo", value: "Amministrazione, finanza e controllo" },
      { label: "Marketing, Comunicazione, Digital e Social Media", value: "Marketing, Comunicazione, Digital e Social Media" },
      { label: "Vendite e Retail", value: "Vendite e Retail" },
      { label: "Diritto e Fisco", value: "Diritto e Fisco" },
      { label: "Gestione di Impresa, Export e Internazionalizzazione", value: "Gestione di Impresa, Export e Internazionalizzazione"},
      { label: "Human Resources, Lavoro e Sviluppo Manageriale", value: "HR, Lavoro e Sviluppo Manageriale"},
      { label: "Arte, Musica, Cinema e Design", value: "Arte, Cinema e Design"},
      { label: "Food, Beverage e Turismo", value: "Food, Beverage e Turismo" },
      { label: "Lusso e Moda", value: "Lusso e Moda" },
      { label: "Sanità, Pharma e Biomed", value: "Sanita, Pharma e Biomed" },
      { label: "Ambiente, Energia e Pubblica Amministrazione", value: "Ambiente, Energia e P.A."},
      { label: "Sport", value: "Sport" },
      { label: "Non ho preferenze", value: "ND" },
    ]}
    error={
      $orientation.hasError('topics.arrayRequired')
        ? 'Seleziona almeno una tematica'
        : null   
    }
  />
  <CustomSelect
    class="col-span-full"
    name="frequency"
    id="frequency"
    label="Quale formula di frequenza preferiresti?"
    bind:value={$frequency.value}
    options={[
      { label: "Formula Full Time (lunedì-giovedì)", value: "full-time" },
      { label: "Formula Weekend (venerdì-sabato)", value: "weekend" },
      { label: "Formula Serale (lunedì-giovedì)", value: "serale" },
      { label: "Preferisco studiare in autonomia (formazione online)", value: "online"},
      { label: "Non ho preferenze", value: "no preferenze" },
    ]}
    error={
      $orientation.hasError('frequency.required')
        ? 'Selezione la formula di frequenza che preferisci'
        : null
    }
  />
  <div class="flex items-start py-3 col-span-full">
    <SwitchToggle 
      class="col-span-full md:col-span-2"
      bind:checked={$financing.value}
      disabled={loading}  
    />
    <span class="ml-2">Sei interessato a finanziare l'acquisto?</span>
  </div>
  <div class="flex items-start py-3 col-span-full">
    <SwitchToggle 
      class="col-span-full md:col-span-2"
      bind:checked={$subscribe.value}
      disabled={loading} 
    />
    <span class="ml-2">
      Attivando questa opzione, do il consenso a Digit’Ed S.p.A. al trattamento
      dei miei dati di contatto per finalità di marketing e comunicazione
      pubblicitaria, su iniziative promozionali, come invio di brochure, coupon
      sconto e promozioni.
      <a href="/privacy-policy" target="_blank">Leggi l'informativa</a>
    </span>
  </div>
</form>
