export class PopupUtils {
  private static readonly POPUP_EXPIRATION_KEY = "popupExpiration";
  private static readonly POPUP_EXPIRATION_TIME = 1000 * 60 * 60 * 24 * 7; // 7 days
  private static readonly POPUP_CLOSED_KEY = "popupClosed";
  private static readonly POPUP_CLOSED_TIME = 1000 * 60 * 60 * 24 * 7; // 7 days

  public static isPopupExpired(): boolean {
    if (typeof window === "undefined") {
      return true;
    }
    const expiration = localStorage.getItem(PopupUtils.POPUP_EXPIRATION_KEY);
    if (expiration === null) {
      return true;
    }
    const expirationDate = new Date(expiration);
    const now = new Date();
    return now > expirationDate;
  }

  public static setPopupExpiration(): void {
    const now = new Date();
    const expirationDate = new Date(now.getTime() + PopupUtils.POPUP_EXPIRATION_TIME);
    localStorage.setItem(PopupUtils.POPUP_EXPIRATION_KEY, expirationDate.toISOString());
  }

  public static clearPopupExpiration(): void {
    localStorage.removeItem(PopupUtils.POPUP_EXPIRATION_KEY);
  }

  public static isPopupClosed(): boolean {
    if (typeof window === "undefined") {
      return false;
    }
    const closed = localStorage.getItem(PopupUtils.POPUP_CLOSED_KEY);
    if (closed === null) {
      return false;
    }
    const closedDate = new Date(closed);
    const now = new Date();
    return now < closedDate;
  }

  public static setPopupClosed(): void {
    const now = new Date();
    const closedDate = new Date(now.getTime() + PopupUtils.POPUP_CLOSED_TIME);
    localStorage.setItem(PopupUtils.POPUP_CLOSED_KEY, closedDate.toISOString());
  }

  public static clearPopupClosed(): void {
    localStorage.removeItem(PopupUtils.POPUP_CLOSED_KEY);
  }

}
